:root {
  font-size: 16px;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  min-width: 360px;
  font-family: "Noto Sans Sinhala", "Milligram", "Lucida Sans Unicode",
    "Lucida Grande", sans-serif;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

#__next {
  min-width: 360px;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.react-multi-carousel-dot-list {
  bottom: 10px !important;
}

.testimonials-slider .slick-track {
  display: flex !important;
}

.testimonials-slider .slick-slide {
  height: inherit !important;
}

.testimonials-slider .slick-slide > div {
  height: 100%;
}

@font-face {
font-family: '__mont_578f15';
src: url(/_next/static/media/eade36a3f7d34941-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__mont_578f15';
src: url(/_next/static/media/67acb00b4abcaddc-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__mont_578f15';
src: url(/_next/static/media/ab4706c076d35b4a-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__mont_Fallback_578f15';src: local("Arial");ascent-override: 62.79%;descent-override: 15.16%;line-gap-override: 32.74%;size-adjust: 115.46%
}.__className_578f15 {font-family: '__mont_578f15', '__mont_Fallback_578f15'
}


:root {
  font-size: 16px;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  min-width: 360px;
  font-family: "Noto Sans Sinhala", "Milligram", "Lucida Sans Unicode",
    "Lucida Grande", sans-serif;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

#__next {
  min-width: 360px;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.react-multi-carousel-dot-list {
  bottom: 10px !important;
}

.testimonials-slider .slick-track {
  display: flex !important;
}

.testimonials-slider .slick-slide {
  height: inherit !important;
}

.testimonials-slider .slick-slide > div {
  height: 100%;
}
